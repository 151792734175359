<template>
  <article class="single-col-page" :class="{ mobile: isMobile }">
    <div class="l-one__col l-one__col--wide">
      <h1>Sign up for updates</h1>
      <p>
        Sign up to receive further information from Electrify America.
        <span>*</span>
      </p>
      <h2 class="headline4">Required Information</h2>
      <form id="signup" @submit.prevent="validate" ref="form">
        <p aria-hidden="true" class="form-legend">An asterisk (<span>*</span>) indicates a required field</p>
        <label ref="firstname" for="first_name"
          >First name <span class="require-mark" aria-hidden="true">*</span>
          <input
            id="first_name"
            maxlength="39"
            name="first_name"
            type="text"
            pattern="^[a-zA-Z0-9]+(?:[a-zA-Z0-9-])+(?:[a-zA-Z0-9])$"
            required
            v-model="firstName"
          />
        </label>
        <label ref="lastname" for="last_name"
          >Last name <span class="require-mark" aria-hidden="true">*</span>
          <input
            id="last_name"
            maxlength="39"
            name="last_name"
            type="text"
            pattern="^[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+(?: +[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+)*$"
            title="This field only accepts letters and special characters (no trailing spaces)"
            required
            v-model="lastName"
          />
        </label>
        <label ref="email" for="email"
          >Email <span class="require-mark" aria-hidden="true">*</span>
          <input
            id="email"
            maxlength="80"
            name="email"
            type="email"
            pattern="[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])"
            title="Please enter the correct email address"
            required
            v-model="email"
          />
        </label>
        <label for="phone"
          >Phone <span class="require-mark" aria-hidden="true">*</span>
          <input
            id="phone"
            maxlength="10"
            name="phone"
            type="text"
            pattern="^\d+$"
            title="This field only accepts numbers"
            required
            v-model="phone"
          />
        </label>
        <h2 class="headline4">Mailing address</h2>
        <label for="address1"
          >Street <span class="require-mark" aria-hidden="true">*</span>
          <textarea maxlength="100" id="address1" name="street" type="text" required v-model="street"></textarea>
        </label>
        <label for="city"
          >City <span class="require-mark" aria-hidden="true">*</span>
          <input id="city" maxlength="30" name="city" type="text" required v-model="city" />
        </label>
        <label for="country_code"
          >Country <span class="require-mark" aria-hidden="true">*</span>
          <select id="country_code" name="country_code" required v-model="country">
            <option value="" disabled selected hidden>Select a country</option>
            <option value="Canada">Canada</option>
            <option value="United States">United States</option>
          </select>
        </label>
        <label for="state_code"
          >{{ isUs ? 'States' : 'Provinces' }} <span class="require-mark" aria-hidden="true">*</span>
          <select id="state_code" name="state_code" :disabled="country === ''" v-model="state" required>
            <option value="" selected hidden>Select a {{ isUs ? 'State' : 'Province' }}</option>
            <option v-for="item in statesProvinceList" :value="item.name" :key="item.abbreviation">
              {{ item.name }}
            </option>
          </select>
        </label>
        <label for="zip">
          {{ isUs ? 'Zip code' : 'Postal code' }} <span class="require-mark" aria-hidden="true">*</span>
          <input
            v-if="isUs"
            id="zip"
            maxlength="5"
            name="zip"
            type="text"
            pattern="^\d+$"
            required
            v-model="postalCode"
          />
          <input
            v-if="!isUs"
            id="zip"
            maxlength="7"
            name="zip"
            type="text"
            pattern="^[a-zA-Z0-9 ]+$"
            required
            v-model="postalCode"
          />
        </label>
        <h2 class="headline4">Preferences (optional)</h2>
        <label for="topic"
          >Topic
          <select id="topic" name="topic" title="Topic" v-model="topic">
            <option value>Select a topic</option>
            <option v-bind:value="topic" v-for="topic in topicOptions" :key="topic">{{ topic }}</option>
          </select>
        </label>
        <label for="communication"
          >Communication
          <select id="communication" name="communication" title="Communication Preference" v-model="communicationPref">
            <option value>Select a communication method</option>
            <option value="Text">Text</option>
            <option value="Email">Email</option>
          </select>
        </label>
        <label for="language"
          >Language
          <select id="language" name="language" title="Language Preference" v-model="languagePref">
            <option value>Select a language</option>
            <option value="English">English</option>
            <option value="Spanish">Spanish</option>
          </select>
        </label>
        <label for="future-comm" class="checkbox">
          <input id="future-comm" name="future-comm" type="checkbox" value="1" required v-model="optForFutureComm" />
          <span>
            I agree to receive further information from Electrify America
            <span class="require-mark" aria-hidden="true">*</span></span
          >
        </label>

        <!-- <div>
            <label for="market-research">
              <input id="market-research" name="market-research" type="checkbox" value="1"/>
              I consent that Electrify America may contact me to potentially engage in market
              research to help improve its network
            </label>
          </div> -->
        <p :style="{ 'font-size': '12px' }">
          <sup>*</sup>I am aware that I may unsubscribe to electronic communications at any time.
        </p>
        <input ref="submitButton" class="button button--submit" name="Sign up" type="submit" value="SUBMIT" />
        <p v-show="serverError !== ''" class="server-error">
          Error:
          <br />
          {{ serverError }}
        </p>
      </form>
    </div>
  </article>
</template>

<script>
import States from '@/assets/json/us_states.json';
import Provinces from '@/assets/json/ca_provinces.json';
import ApiService from '@/services/Api';

export default {
  name: 'sign-up',
  metaInfo: {
    title: 'Sign up for updates | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Want to stay up-to-date on the latest news about Electrify America’s electric vehicle (EV) public charging network? Use the form to subscribe and get updates.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/sign-up/' }],
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      street: null,
      city: null,
      state: '',
      statesProvinceList: States,
      postalCode: null,
      country: 'United States',
      topic: '',
      communicationPref: null,
      languagePref: null,
      optForFutureComm: null,
      optForMarketResearch: false,
      serverError: '',
      topicOptions: ['Company News', 'Events', 'Locations', 'Press Releases', 'Pricing', 'Sustainability'],
    };
  },
  created() {
    if (this.$route.query.topic && this.topicOptions.includes(this.$route.query.topic)) {
      this.topic = this.$route.query.topic;
    }
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isUs() {
      return this.country === 'United States';
    },
  },
  methods: {
    resetLabelColor() {
      this.$refs.email.style.color = '#505875';
      this.$refs.firstname.style.color = '#505875';
      this.$refs.lastname.style.color = '#505875';
    },
    async validate(e) {
      e.preventDefault();
      this.serverError = '';
      try {
        const params = {
          site: 'ELAM',
          recaptchaToken: await this.recaptcha(),
          body: {
            FirstName: this.firstName,
            LastName: this.lastName,
            Email: this.email,
            Phone: this.phone,
            Street: this.street,
            City: this.city,
            State: this.state,
            PostalCode: this.postalCode,
            Country: this.country,
            Topics: this.topic,
            CommunicationPref: this.communicationPref,
            LanguagePref: this.languagePref,
            OptForFutureComm: this.optForFutureComm,
            OptForMarketResearch: this.optForMarketResearch,
          },
        };
        this.signUpPut(params);
      } catch (error) {
        this.$refs.submitButton.disabled = false;
      }
    },
    async recaptcha() {
      try {
        // Wait for recaptcha loading
        await this.$recaptchaLoaded();
        // Execute reCAPTCHA with action "login" and return the token
        return await this.$recaptcha('login');
      } catch (e) {
        throw new Error('recaptcha login failed');
      }
    },
    signUpPut(params) {
      const loader = this.$loading.show();
      this.$store.commit('setEmail', params.body.Email);
      ApiService.init();
      ApiService.put('/v1/salesforce/signupForm', params)
        .then((res) => {
          if (res.status === 'success') {
            this.$router.push({ name: 'email-verification-en' });
          } else {
            this.serverError = res.errorMsg;
          }
          loader.hide();
          this.$refs.submitButton.disabled = false;
        })
        .catch((error) => {
          // Commenting out surfacing Salesforce errors to end users until we've made messaging more robust
          /*
          if (error.response.status === 500) {
            this.serverError = 'There was an error submitting the form, please try again';
          } else {
            this.serverError = error.response.data.errMsg;
          }
          */
          this.serverError = 'There was an error submitting the form, please try again';

          this.$refs.submitButton.disabled = false;
          loader.hide();
          console.error(error);
        });
    },
  },
  watch: {
    serverError(newValue) {
      this.resetLabelColor();
      if (newValue !== '') {
        if (newValue.includes('Email')) {
          this.$refs.email.style.color = '#d90041';
          this.$refs.email.scrollIntoView();
        } else if (newValue.includes('Duplicate')) {
          this.$refs.firstname.style.color = '#d90041';
          this.$refs.lastname.style.color = '#d90041';
          this.$refs.firstname.scrollIntoView();
        }
      }
    },
    country(newCountry) {
      switch (newCountry) {
        case 'United States':
          this.statesProvinceList = States;
          break;
        case 'Canada':
          this.statesProvinceList = Provinces;
          break;
        default:
          this.statesProvinceList = [];
      }
      this.state = '';
    },
  },
  mounted() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.showBadge();
    });
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
};
</script>

<style scoped lang="scss">
h1 + p {
  font-size: 18px;

  span {
    font-size: 13px;
  }
}
</style>
